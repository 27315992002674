<template>
  <div>
    {{ value() }}
  </div>
</template>

<script>
import { getOptionLabel } from "../../../helpers/common";
export default {
  name: "TableType",

  props: {
    field: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },

  methods: {
    value() {
      return getOptionLabel(this.data[this.field], this.options);
    },
  },
};
</script>
